import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart,pieArcLabelClasses  } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class UserActivity extends React.Component {
    constructor(props) {
      super(props);
        console.log(props.dates)
      this.state = {isLoading: false,error:false, dates:this.props.dates, dataSpendTime:{},dataActivity:[],spendTimeUsersNB:[],dataActivityReact:[],dataActivityComments:[],dataActivityContents:[],dataActivityTotal:[],usersPlatform:{}, usersGender:{},usersAge:{}};
     
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
       
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsUserActivity.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer.usersGender)
                    if(responseJsonFromServer.statusCode == 1){
               
                        this.setState({isLoading:false, 
                            dataSpendTime:responseJsonFromServer.dataSpendTime,
                            dataActivity:responseJsonFromServer.dataActivity,
                            dataActivityReact:responseJsonFromServer.dataActivityReact,
                            spendTimeUsersNB:responseJsonFromServer.spendTimeUsersNB,
                            dataActivityComments:responseJsonFromServer.dataActivityComments,
                            dataActivityContents:responseJsonFromServer.dataActivityContents,
                            dataActivityTotal:responseJsonFromServer.dataActivityTotal,
                            usersPlatform:responseJsonFromServer.usersPlatform,
                            usersGender:responseJsonFromServer.usersGender,
                            usersAge:responseJsonFromServer.usersAge
                          
                       })
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
        // var dates = [props.dates[0].format('YYYY-MM-DD')];
        
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })  
       }
    }
 
    render() {
        var percentSpendTime;
        if(this.state.spendTimeUsersNB.length > 0){
            percentSpendTime = Math.round(((parseInt(this.state.spendTimeUsersNB[0])-parseInt(this.state.spendTimeUsersNB[1]))/parseInt(this.state.spendTimeUsersNB[1]))*100)
        }
        if(this.state.dataActivity.length > 0){
            var datasetActivity = this.state.dataActivity.map(([high, low, order]) => ({
                high,
                low,
                order,
              }));
        }
        if(this.state.dataActivityComments.length > 0){
            var datasetActivityComments = this.state.dataActivityComments.map(([high, low, order]) => ({
                high,
                low,
                order,
              }));
        }
        if(this.state.dataActivityContents.length > 0){
            var datasetActivityCreated = this.state.dataActivityContents.map(([high, low, order]) => ({
                high,
                low,
                order,
              }));
        }
        if(this.state.dataActivityReact.length > 0){
            var datasetActivityReacted = this.state.dataActivityReact.map(([high, low, order]) => ({
                high,
                low,
                order,
              }));
        }
        var percentAndroid = 50;
        var percentIOS = 50;
        var percentIOSBefore = 50;
        var percentAndroidBefore = 50;
        if(Object.keys(this.state.usersPlatform).length > 0){
            percentAndroid = ((this.state.usersPlatform.now.android)/parseInt(this.state.usersPlatform.now.ios+this.state.usersPlatform.now.android))*100
            percentIOS = 100-percentAndroid
            percentAndroidBefore = ((this.state.usersPlatform.before.android)/parseInt(this.state.usersPlatform.before.ios+this.state.usersPlatform.before.android))*100
            percentIOSBefore = 100-percentAndroidBefore
        }

        var percentH = 50;
        var percentF = 50;
        var percentFBefore = 50;
        var percentHBefore = 50;
        if(Object.keys(this.state.usersGender).length > 0){
            percentH = ((this.state.usersGender.now.H)/parseInt(this.state.usersGender.now.F+this.state.usersGender.now.H))*100
            percentF = 100-percentH
            percentHBefore = ((this.state.usersGender.before.H)/parseInt(this.state.usersGender.before.F+this.state.usersGender.before.H))*100
            percentFBefore = 100-percentHBefore
        }

        var percentAge1 = 33;
        var percentAge2 = 33;
        var percentAge3 = 33;
        var percentAge1Before = 33;
        var percentAge2Before = 33;
        var percentAge3Before = 33;
        if(Object.keys(this.state.usersAge).length > 0){
            var ageTotal = (parseInt(this.state.usersAge.now.age1)+parseInt(this.state.usersAge.now.age2)+parseInt(this.state.usersAge.now.age3))
            var ageTotalBefore = (parseInt(this.state.usersAge.before.age1)+parseInt(this.state.usersAge.before.age2)+parseInt(this.state.usersAge.before.age3))
            percentAge1 = (parseInt(this.state.usersAge.now.age1)/parseInt(ageTotal))*100
            percentAge2 = ((parseInt(this.state.usersAge.now.age2))/parseInt(ageTotal))*100
            percentAge3 = ((parseInt(this.state.usersAge.now.age3))/parseInt(ageTotal))*100
            
            percentAge1Before = (parseInt(this.state.usersAge.before.age1)/parseInt(ageTotalBefore))*100
            percentAge2Before = ((parseInt(this.state.usersAge.before.age2))/parseInt(ageTotalBefore))*100
            percentAge3Before = ((parseInt(this.state.usersAge.before.age3))/parseInt(ageTotalBefore))*100
        }



      
      return (
        <div style={{flex:1, display:'flex'}}>
            {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="medium" text="" textColor="" />
            </div>
            :
            <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                 <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} className='cat'>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Temps moyen passé (en min) <span style={{fontWeight:'bold', marginLeft:10,color:percentSpendTime > 0 ? 'green' : 'red'}}>{percentSpendTime > 0 ? '+' : ''}{percentSpendTime}%</span></p>
                        {this.state.dataSpendTime.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgba(255,255,255,0.5)']} color={'white'}  borderRadius={15}
                        xAxis={[{scaleType:'point', data: this.state.dataSpendTime[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataSpendTime[0]?.series,
                            },   {
                                data: this.state.dataSpendTime[1]?.series,
                                },
                        ]}
                        /></ThemeProvider>}
                      
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Activité par rapport aux actifs</p>
                        <p style={{color:'white', fontSize:14,marginTop:0, paddingTop:0 }}>Inclu les commentaires, réactions et votes sur un contenu, créations de contenus</p>
                        {this.state.dataActivity.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={['rgb(26, 53, 91)','white']} color={'white'}  series={[
                        { dataKey: 'high', label: 'Nombre d\'activité', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Total d\'actifs', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={datasetActivity} /></ThemeProvider>}
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux d'activité utilisateurs depuis J1 par rapport aux actifs</p>
                        <div style={{flex:1, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                            <p style={{color:'white', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round((parseInt(this.state.dataActivityTotal?.userActivityNB))/parseInt(this.state.dataActivityTotal?.totalUsers)*100)}%</p>
                            <p style={{color:'white', fontSize:25, padding:10, margin:0 }}>{this.state.dataActivityTotal?.userActivityNB} sur {this.state.dataActivityTotal?.totalUsers} utilisateurs</p>
                        </div>
                    </div>
                </div>

                <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}}  className='cat'>
                     <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}}   className='sub_cat'>  
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Commentaires crées par rapport aux actifs</p>
                        {this.state.dataActivityComments.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={[ 'rgb(26, 53, 91)','white']} color={'white'}  series={[
                        { dataKey: 'high', label: 'Commentaires', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Total d\'actifs', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={datasetActivityComments} /></ThemeProvider>}
                    </div>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Contenus crées par rapport aux actifs</p>
                        {this.state.dataActivityContents.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={['rgb(26, 53, 91)','white']} color={'white'}  series={[
                        { dataKey: 'high', label: 'Contenus crées', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Total d\'actifs', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={datasetActivityCreated} /></ThemeProvider>}
                    </div>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Réactions/Votes par rapport aux actifs</p>
                        {this.state.dataActivityReact.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={['rgb(26, 53, 91)','white']} color={'white'}  series={[
                        { dataKey: 'high', label: 'Réactions/Votes', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Total d\'actifs', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={datasetActivityReacted} /></ThemeProvider>}
                    </div>
                </div>
                <div style={{flex:1, display:'flex', columnGap:'25px',  margin:'25px', marginBottom:'0px'}}  className='cat'>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Type de plateforme</p>
                        <div style={{display:'flex', flex:1}}>
                        <div style={{display:'flex', flex:1, flexDirection:'column', height:300}}>                        
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: percentIOSBefore, label: 'IOS', color:'rgb(240, 83, 19)' },
                                            { id: 1, value: percentAndroidBefore, label: 'Android', color:"rgb(56, 161, 78)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                            
                                <p style={{color:'white', fontSize:15, fontWeight:'bold',textAlign:'center' }}>Periode précedente</p>
                            </div>
                            <div style={{display:'flex', flex:1, flexDirection:'column', height:300}}>                        
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: percentIOS, label: 'IOS', color:'rgb(240, 83, 19)' },
                                            { id: 1, value: percentAndroid, label: 'Android', color:"rgb(56, 161, 78)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                            
                                <p style={{color:'white', fontSize:15, fontWeight:'bold',textAlign:'center' }}>Periode actuelle</p>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column',}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Genre des utilisateurs</p>
                        <div style={{display:'flex', flex:1}}>
                            <div style={{display:'flex', flex:1, flexDirection:'column',  height:300}}>                        
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: percentFBefore, label: 'Femme', color:'rgb(121, 19, 59)' },
                                            { id: 1, value: percentHBefore, label: 'Homme', color:"rgb(0, 0, 255)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                            
                                <p style={{color:'white', fontSize:15, fontWeight:'bold',textAlign:'center' }}>Periode précédente</p>
                            </div>
                            <div style={{display:'flex', flex:1, flexDirection:'column', height:300}}>                        
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: percentF, label: 'Femme', color:'rgb(121, 19, 59)' },
                                            { id: 1, value: percentH, label: 'Homme', color:"rgb(0, 0, 255)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                            
                                <p style={{color:'white', fontSize:15, fontWeight:'bold',textAlign:'center' }}>Periode actuelle</p>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Age des utilisateurs</p>
                        <div style={{display:'flex', flex:1}}>
                            <div style={{display:'flex', flex:1, flexDirection:'column', height:300}}>                        
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: percentAge1Before, label: '15-17', color:'rgb(0, 186, 173)' },
                                            { id: 1, value: percentAge2Before, label: '18-21', color:"rgb(42, 123, 155)" },
                                            { id: 2, value: percentAge3Before, label: '22+', color:"rgb(61, 61, 107)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                            
                                <p style={{color:'white', fontSize:15, fontWeight:'bold',textAlign:'center' }}>Periode précédente</p>
                            </div>
                            <div style={{display:'flex', flex:1, flexDirection:'column',  height:300}}>                        
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: percentAge1, label: '15-17', color:'rgb(0, 186, 173)' },
                                            { id: 1, value: percentAge2, label: '18-21', color:"rgb(42, 123, 155)" },
                                            { id: 2, value: percentAge3, label: '22+', color:"rgb(61, 61, 107)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                            
                                <p style={{color:'white', fontSize:15, fontWeight:'bold',textAlign:'center' }}>Periode actuelle</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            }
        </div>
      );
    }
  }