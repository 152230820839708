import logo from '../logo.png';
import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { Navigate } from 'react-router-dom';
import Main from './CustomComponents/Main'
import Registers from './CustomComponents/Registers'
import UserActivity from './CustomComponents/UserActivity'
import Economy from './CustomComponents/Economy'
import Retention from './CustomComponents/Retention'
import Contents from './CustomComponents/Contents'
import Inactives from './CustomComponents/Inactives'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-multi-date-picker";

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        var lastWeek = new Date().getTime()-604800000;
        this.state = {isLoading: false, error:false,pageSelected:0, dates:[new Date(lastWeek),new Date(),], datesSelected:[new Date(lastWeek),new Date(),] };
    }
  
    componentDidMount(){
        this.setState({isLoading:true}, () => {
            const token = localStorage.getItem('token');
            console.log('token', token)
            fetch('https://sondago.com/mobile/Admin/Stats/homeLoad.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer)
                    if(responseJsonFromServer.statusCode == 1){
                        
                        this.setState({isLoading:false})
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                   
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }
  
   
    _setDate = (date) => {
       
        this.setState({dates:date})
        
    }

    _checkCalendar = () => {
        if(this.state.dates.length < 2){
            toast("Selectionne au moins 2 dates", {position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"});
                return false;
        }
        var dates = [this.state.dates[0].format('YYYY-MM-DD'),this.state.dates[1].format('YYYY-MM-DD') ]
        console.log(dates)
        this.setState({datesSelected:dates})
    }
    

    render() {
        var tabStats = [['Accueil',  <Main dates={this.state.datesSelected}/>], ['Inscriptions', <Registers dates={this.state.datesSelected}/>], ['Utilisateurs actifs', <UserActivity dates={this.state.datesSelected}/>], ["Taux de retention",  <Retention dates={this.state.datesSelected}/>], ["Perte d'utilisateurs", <Inactives dates={this.state.datesSelected}/>],["Revenus",   <Economy dates={this.state.datesSelected}/>], ["Contenus", <Contents dates={this.state.datesSelected}/>]]
      return (
        <div className="App">
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            {this.state.error && <Navigate to="/connect" replace={true}/>}
           {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="large" text="" textColor="" />
            </div>
            :
            <div style={{flex:1,display:'flex', flexDirection:'column',overflow:'auto'}}>
              
                <div style={{flex:1, display:"flex", flexDirection:'column', marginTop:25,}}>
                    <div style={{display:'flex', flexWrap:'wrap', alignSelf:'center', width:'97%', justifyContent:'space-between', display:'flex',  alignItems:'center', columnGap:10, rowGap:10}} id='main_menu'>
                       
                        <div style={{alignItems:'center', flex:1, flexWrap:'wrap',  flexDirection:'row', display:'flex',   columnGap:'10px', rowGap:10, justifyContent:'center'}}>
                            <button onClick={() => {this.setState({pageSelected:0})}} className='glow-on-hover' style={{backgroundColor:this.state.pageSelected == 0 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px',}}>
                                <p style={{fontWeight:'bold', color:'white'}}>Accueil</p>
                            </button>
                        
                            <button onClick={() => {this.setState({pageSelected:1})}} className='glow-on-hover'  style={{backgroundColor:this.state.pageSelected == 1 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px', }}>
                                <p style={{fontWeight:'bold', color:'white'}}>Inscriptions</p>
                            </button>
                            <button  onClick={() => {this.setState({pageSelected:2})}} className='glow-on-hover'  style={{backgroundColor:this.state.pageSelected == 2 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px', }}>
                                <p style={{fontWeight:'bold', color:'white'}}>Utilisateurs actifs</p>
                            </button>
                            <button  onClick={() => {this.setState({pageSelected:3})}} className='glow-on-hover'  style={{backgroundColor:this.state.pageSelected == 3 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px',}}>
                                <p style={{fontWeight:'bold', color:'white'}}>Taux de retention</p>
                            </button>
                            <button  onClick={() => {this.setState({pageSelected:4})}} className='glow-on-hover'  style={{backgroundColor:this.state.pageSelected == 4 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px'}}>
                                <p style={{fontWeight:'bold', color:'white'}}>Perte d'utilisateurs</p>
                            </button>

            
                            <button  onClick={() => {this.setState({pageSelected:5})}}  className='glow-on-hover' style={{backgroundColor:this.state.pageSelected == 5 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px', }}>
                                <p style={{fontWeight:'bold', color:'white'}}>Revenus</p>
                            </button>
                            <button   onClick={() => {this.setState({pageSelected:6})}} className='glow-on-hover'  style={{backgroundColor:this.state.pageSelected == 6 ? 'rgb(107, 5, 69)' : 'rgba(255,255,255,0.2)', padding:'5px', borderRadius:'25px',}}>
                                <p style={{fontWeight:'bold', color:'white'}}>Contenus</p>
                            </button>
                        </div>
                        <DatePicker maxDate={new Date()} onClose={this._checkCalendar} value={this.state.dates} style={{backgroundColor:'rgba(0,0,0,0.3)', color:'white', fontWeight:'bold', padding:'10px', borderColor:'transparent', fontSize:'20px'}}  dateSeparator=" à " format='DD/MM/YYYY'   range onChange={this._setDate} multiple={false} />
                     

                    </div>
                    {tabStats[this.state.pageSelected][1]}
                </div>
            </div>}
            
        </div>
      );
    }
  }