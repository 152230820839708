import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {isLoading: false,error:false, dates:this.props.dates, dataUsersConnected:{}, dataUsersRegistered:{},dataRetention:{}, dataMaxConnected:{},dataContents:{}, dataMiddleConnect:[]};
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
        console.log('dates', this.state.dates)
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsMain.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer)
                    if(responseJsonFromServer.statusCode == 1){
                        console.log(responseJsonFromServer.dataMiddleConnect)
                        this.setState({isLoading:false, 
                        dataUsersConnected:responseJsonFromServer.dataUsersConnected, 
                        dataUsersRegistered:responseJsonFromServer.dataUsersRegistered,
                        dataRetention:responseJsonFromServer.dataRetention,
                        dataMaxConnected:responseJsonFromServer.dataMaxConnected,
                        dataMiddleConnect:responseJsonFromServer.dataMiddleConnect,
                        dataContents:responseJsonFromServer.dataContents})
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
      
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })
      
         
       }
    }
 
    render() {
        var percentConnexions;
        if(this.state.dataUsersConnected.length > 0){
            let percent1 = this.state.dataUsersConnected[0].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            let percent2 = this.state.dataUsersConnected[1].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            percentConnexions = Math.round(((parseInt(percent1)-parseInt(percent2))/parseInt(percent2))*100)
        }
        var percentRegisters;
        if(this.state.dataUsersRegistered.length > 0){
            let percent1 = this.state.dataUsersRegistered[0].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            let percent2 = this.state.dataUsersRegistered[1].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            percentRegisters = Math.round(((parseInt(percent1)-parseInt(percent2))/parseInt(percent2))*100)
        }
        var percentPic;
        if(this.state.dataMaxConnected.length > 0){
            let percent1 = this.state.dataMaxConnected[0].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            let percent2 = this.state.dataMaxConnected[1].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            percentPic = Math.round(((parseInt(percent1)-parseInt(percent2))/parseInt(percent2))*100)
        }
        var percentContents;
        if(this.state.dataContents.length > 0){
            let percent1 = this.state.dataContents[0].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            let percent2 = this.state.dataContents[1].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            percentContents = Math.round(((parseInt(percent1)-parseInt(percent2))/parseInt(percent2))*100)
        }
        var percentSpendTime;
        if(this.state.dataMiddleConnect.length > 0){
            let percent1 = this.state.dataMiddleConnect[0].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            let percent2 = this.state.dataMiddleConnect[1].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            percentSpendTime = Math.round(((parseInt(percent1)-parseInt(percent2))/parseInt(percent2))*100)
        }
        var percentRetention
        if(this.state.dataRetention.length > 0){
            let percent1 = Math.round((((parseInt(this.state.dataRetention[0].series[this.state.dataRetention[0].series.length-1]))-parseInt(this.state.dataRetention[0].series[0]))/parseInt(this.state.dataRetention[0].series[0]))*100)
            percent1 = 100-parseInt(String(percent1).replace('-', ''))
       
            let percent2 = Math.round((((parseInt(this.state.dataRetention[1].series[this.state.dataRetention[1].series.length-1]))-parseInt(this.state.dataRetention[1].series[0]))/parseInt(this.state.dataRetention[1].series[0]))*100)
            percent2 = 100-parseInt(String(percent2).replace('-', ''))
            percentRetention = percent1-percent2
            
        }
        
      return (
        <div style={{flex:1, display:'flex'}}>
            {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="medium" text="" textColor="" />
            </div>
            :
            <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                <div style={{flex:1, display:'flex', columnGap:'25px',  margin:'25px', marginBottom:'0px'}} className="cat">
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column',}} className="sub_cat">
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Connexions par jour <span style={{fontWeight:'bold', color:percentConnexions > 0 ? 'green' : 'red', marginLeft:10}}>{percentConnexions > 0 ? '+' : ''}{percentConnexions}%</span></p>
                        {this.state.dataUsersConnected.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgb(255,255,255,0.7)']} color={'white'}  borderRadius={15}
                        xAxis={[{scaleType:'band', data: this.state.dataUsersConnected[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataUsersConnected[0]?.series,
                            }, {
                                data: this.state.dataUsersConnected[1]?.series,
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}}  className="sub_cat">
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Inscriptions par jour <span style={{fontWeight:'bold', color:percentRegisters > 0 ? 'green' : 'red', marginLeft:10}}>{percentRegisters > 0 ? '+' : ''}{percentRegisters}%</span></p>
                        {this.state.dataUsersRegistered.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgb(255,255,255,0.7)']} color={'white'} borderRadius={15}
                        xAxis={[{scaleType:'band', data: this.state.dataUsersRegistered[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataUsersRegistered[0]?.series,
                            }, {
                                data: this.state.dataUsersRegistered[1]?.series,
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}}  className="sub_cat">
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Retention des inscrits <span style={{fontWeight:'bold', color:percentRetention > 0 ? 'green' : 'red', marginLeft:10}}>{percentRetention > 0 ? '+' : ''}{percentRetention}%</span></p>
                        {this.state.dataRetention.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgb(255,255,255,0.7)']} color={'white'} borderRadius={15}  
                        xAxis={[{scaleType:'point', data: this.state.dataRetention[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataRetention[0]?.series,
                           
                            },  {
                                data: this.state.dataRetention[1]?.series,
                               
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                    
                </div>
                <div style={{flex:1, display:'flex', columnGap:'25px',  margin:'25px', marginBottom:'0px'}} className="cat">
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}}  className="sub_cat">
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Pic de connexions <span style={{fontWeight:'bold', color:percentPic > 0 ? 'green' : 'red', marginLeft:10}}>{percentPic > 0 ? '+' : ''}{percentPic}%</span></p>
                        {this.state.dataMaxConnected.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgb(255,255,255,0.7)']} color={'white'}  borderRadius={15}
                        xAxis={[{scaleType:'point', data: this.state.dataMaxConnected[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataMaxConnected[0]?.series,
                            }, {
                                data: this.state.dataMaxConnected[1].series,
                               
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}}  className="sub_cat">
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Contenus crées par jour <span style={{fontWeight:'bold', color:percentContents > 0 ? 'green' : 'red', marginLeft:10}}>{percentContents > 0 ? '+' : ''}{percentContents}%</span></p>
                        {this.state.dataContents.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgb(255,255,255,0.5)']} color={'white'} borderRadius={15}
                        xAxis={[{scaleType:'point', data: this.state.dataContents[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataContents[0]?.series,
                            }, {
                                data: this.state.dataContents[1]?.series,
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}}  className="sub_cat">
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Temps passé moyen des utilisateurs (en min) <span style={{fontWeight:'bold', color:percentSpendTime > 0 ? 'green' : 'red', marginLeft:10}}>{percentSpendTime > 0 ? '+' : ''}{percentSpendTime}%</span></p>
                        {this.state.dataMiddleConnect.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white','rgb(255,255,255,0.5)']} color={'white'} borderRadius={15}  
                        xAxis={[{scaleType:'point', data: this.state.dataMiddleConnect[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataMiddleConnect[0]?.series,
                            },   {
                                data: this.state.dataMiddleConnect[1]?.series,
                                }, 
                        ]}
                        /></ThemeProvider>}
                    </div>
                    
                </div>
            </div>
            }
        </div>
      );
    }
  }