import logo from '../logo.png';
import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
export default class Connect extends React.Component {
    constructor(props) {
      super(props);
      this.state = {isLoadingConnect: false, inputValue:'', isLogged:false};
    }
  
    connect = (e) => {
        e.preventDefault();
        this.setState({isLoadingConnect:true}, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/checkAccessStats.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'password='+this.state.inputValue
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer)
                    if(responseJsonFromServer.statusCode == 1){
                        localStorage.setItem('token', responseJsonFromServer.token)
                       this.setState({isLogged:true})
                    }else{
                        this.setState({isLoadingConnect:false})
                        toast("Mot de passe incorrect", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    console.log(error)
                    this.setState({isLoadingConnect:false})
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    updateInputValue(evt) {
        const val = evt.target.value;
        // ...       
        this.setState({
          inputValue: val
        });
      }
  
    render() {
      return (
        <div className="App">
            {this.state.isLogged && <Navigate to="/home" replace={true}/>}
            <header className="App-header">
            <img src={logo}  alt="logo" />
            <h3>Sondago Statistiques</h3>
            </header>
            <div style={{flex:1,display:"flex",justifyContent:'center'}}>
            <div style={{flexDirection:'row'}}>
                {!this.state.isLoadingConnect ?
                <form onSubmit={this.connect}>
                    <input value={this.state.inputValue} onChange={evt => this.updateInputValue(evt)} type="password"  style={{backgroundColor:'rgba(255,255,255,0.5)',borderColor:'transparent', padding:'10px',width:'300px', fontSize:'20px', fontWeight:'bold',height:'30px',borderRadius:'20px', boxShadow:'none', color:'white'}} className="placeholder" placeholder='Mot de passe' />
                    <input  type="submit" style={{width:'80px', height:'55px', padding:'10px', margin:'15px', borderRadius:'15px', backgroundColor:'white', borderColor:'transparent', color:'rgb(165, 2, 99)', fontWeight:'bold', fontSize:"25px"}} value={'OK'}/>
                </form>
                :
                <LifeLine color="white" size="medium" text="" textColor="" />}
            </div>
            <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
            </div>
        </div>
      );
    }
  }