import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class Economy extends React.Component {
    constructor(props) {
      super(props);
        console.log(props.dates)
      this.state = {isLoading: false,error:false, dates:this.props.dates,paymentsWin:[], dataNBPurchases:[],dataTypesPurchases:[], dataFirstPurchases:[]};
     
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
        console.log('dates', this.state.dates)
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsEconomy.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer)
                    if(responseJsonFromServer.statusCode == 1){
                        console.log(responseJsonFromServer.dataFirstPurchases)
                        this.setState({isLoading:false, 
                            paymentsWin:responseJsonFromServer.paymentsWin,
                            dataNBPurchases:responseJsonFromServer.dataNBPurchases,
                            dataTypesPurchases:responseJsonFromServer.dataTypesPurchases,
                            dataFirstPurchases:responseJsonFromServer.dataFirstPurchases
                       })
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
        // var dates = [props.dates[0].format('YYYY-MM-DD')];
        
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })  
       }
    }
 
    render() {
        var percentWin;
        if(this.state.paymentsWin.length > 0){
            percentWin = Math.round(((parseInt(this.state.paymentsWin[0])-parseInt(this.state.paymentsWin[1]))/parseInt(this.state.paymentsWin[1]))*100)
        }
        var percentFirstPurchaseOld;
        var percentFirstPurchase;
        if(this.state.dataFirstPurchases.length > 0){
            percentFirstPurchaseOld = Math.round(((parseInt(this.state.dataFirstPurchases[1].first)*100)/parseInt(this.state.dataFirstPurchases[1].all)))
            percentFirstPurchase = Math.round(((parseInt(this.state.dataFirstPurchases[0].first)*100)/parseInt(this.state.dataFirstPurchases[0].all)))
        }
        
        return (
            <div style={{flex:1, display:'flex'}}>
                {this.state.isLoading ?
                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                    <LifeLine color="white" size="medium" text="" textColor="" />
                </div>
                :
                <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                    <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} className='cat'>
                     
                        <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',}} className='sub_cat_flex'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Revenus</p>
                            <div style={{flexDirection:'column', display:'flex', flex:1}}>                     
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                    <p style={{color:this.state.paymentsWin[0] > this.state.paymentsWin[1] ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round(this.state.paymentsWin[0])}€</p>
                                    <p style={{fontSize:13, marginTop:0, paddingTop:0,color:(this.state.paymentsWin[0] > this.state.paymentsWin[1]) ? 'green' : 'red'}}>{this.state.paymentsWin[0] > this.state.paymentsWin[1]  ? "+" : ""}{percentWin}% que la periode précedente</p>
                                </div>
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0,}}>{Math.round(this.state.paymentsWin[1])}€</p>
                                    
                                </div>
                            </div>
                            
                        </div>
                           
                       
                        <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Nombres d'achats</p>
                            {this.state.dataNBPurchases.length > 0 &&
                            <ThemeProvider theme={darkTheme}>                 
                            <LineChart colors={[ 'green', 'rgba(255,255,255,0.3)']} grid={{ vertical: true, horizontal: true }} color={'white'} borderRadius={15}  
                            xAxis={[{scaleType:'point', data: this.state.dataNBPurchases[0].xAxis, }]}
                            series={[
                                {
                                data: this.state.dataNBPurchases[0].series,
                            
                                }, {
                                    data: this.state.dataNBPurchases[1].series,
                                
                                    },
                            ]}
                            /></ThemeProvider>}
                        </div>    
                    </div>
                    <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} className='cat'>
                        <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',}} className='sub_cat_flex'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Achats pour la 1ere fois</p>
                            <div style={{flexDirection:'column', display:'flex', flex:1}}>    
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                    <p style={{color:percentFirstPurchase > percentFirstPurchaseOld ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentFirstPurchase}%</p>
                                    <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.dataFirstPurchases[0]?.first} / {this.state.dataFirstPurchases[0]?.all} utilisateurs</p>
                                </div>
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentFirstPurchaseOld}%</p>
                                    <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.dataFirstPurchases[1]?.first} / {this.state.dataFirstPurchases[1]?.all} utilisateurs</p>
                                </div>
                            
                            </div>
                            
                        </div>
                        {this.state.dataTypesPurchases.length > 0 &&
                        <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)', borderRadius:'15px', flexDirection:'column'}} className='sub_cat_pie'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Types d'achats</p>
                            <div style={{ display:'flex',flex:1,  columnGap:150 }} className='cat'>
                                
                                <div style={{display:'flex', flex:1, flexDirection:'column', margin:'0 auto', alignItems:'center', justifyContent:'center'}}>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                    <ThemeProvider theme={darkTheme}>               
                                    <PieChart width={undefined} 
                                    series={[
                                        {
                                        data: this.state.dataTypesPurchases[1], innerRadius: 36,
                                        outerRadius: 100,
                                        paddingAngle: 5,
                                        cornerRadius: 5,
                                        startAngle: -45,
                                        endAngle: 225,
                                        cx: 150,
                                        cy: 150,
                                        },
                                    ]}
                                    />
                                    </ThemeProvider>
                                </div>
                              
                              
                                <div style={{display:'flex', flex:1, flexDirection:'column', margin:'0 auto', alignItems:'center', justifyContent:'center'}}>
                                    <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                    <ThemeProvider theme={darkTheme}>               
                                    <PieChart  width={undefined} 
                                    series={[
                                        {
                                        data: this.state.dataTypesPurchases[0], innerRadius: 36,
                                        outerRadius: 100,
                                        paddingAngle: 5,
                                        cornerRadius: 5,
                                        startAngle: -45,
                                        endAngle: 225,
                                        cx: 150,
                                        cy: 150,
                                        },
                                    ]}
                                    />
                                    </ThemeProvider>
                                </div>
                               
                            </div>
                        </div>}
                      
                    </div>
                   
                </div>
                }
            </div>
      );
    }
  }