import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class Contents extends React.Component {
    constructor(props) {
      super(props);
        console.log(props.dates)
      this.state = {isLoading: false,error:false, dates:this.props.dates,contentsCreated:[],dataContents:[], contentsSeen:[], repostsNB:[], popularScore:[]};
     
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
        console.log('dates', this.state.dates)
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsContents.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer.popularScore) 
                    if(responseJsonFromServer.statusCode == 1){
               
                        this.setState({isLoading:false, 
                            contentsCreated:responseJsonFromServer.contentsCreated,
                            dataContents:responseJsonFromServer.dataContents,
                            contentsSeen:responseJsonFromServer.contentsSeen,
                            repostsNB:responseJsonFromServer.repostsNB,
                            popularScore:responseJsonFromServer.popularScore
                            
                       })
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
        // var dates = [props.dates[0].format('YYYY-MM-DD')];
        
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })  
       }
    }
 
    render() {
        var percentUniqUsersOld;
        var percentUniqUsers;
        if(this.state.contentsCreated.length > 0){
            percentUniqUsersOld = Math.round(((parseInt(this.state.contentsCreated[1].totalUsers)*100)/parseInt(this.state.contentsCreated[1].contentsNB)))
            percentUniqUsers = Math.round(((parseInt(this.state.contentsCreated[0].totalUsers)*100)/parseInt(this.state.contentsCreated[0].contentsNB)))
        }
        var percentRepostsOld;
        var percentReposts;
        if(this.state.contentsCreated.length > 0 && this.state.repostsNB.length > 0){
            percentRepostsOld = Math.round(((parseInt(this.state.repostsNB[1])*100)/parseInt(this.state.contentsCreated[1].contentsNB)))
            percentReposts = Math.round(((parseInt(this.state.repostsNB[0])*100)/parseInt(this.state.contentsCreated[0].contentsNB)))
        }
        var percentSeenOld;
        var percentSeen;
        if(this.state.contentsSeen.length > 0){
            percentSeenOld = Math.round(((parseInt(this.state.contentsSeen[1].seenNB)*100)/parseInt(this.state.contentsSeen[1].usersNB)))
            percentSeen = Math.round(((parseInt(this.state.contentsSeen[0].seenNB)*100)/parseInt(this.state.contentsSeen[0].usersNB)))
        }
        var percentContents;
        if(this.state.dataContents.length > 0){
            let percent1 = this.state.dataContents[0].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            let percent2 = this.state.dataContents[1].series.reduce(function(previousValue, currentValue){
                return currentValue + previousValue;
            });
            percentContents = Math.round(((parseInt(percent1)-parseInt(percent2))/parseInt(percent2))*100)
        }
      return (
        <div style={{flex:1, display:'flex'}}>
            {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="medium" text="" textColor="" />
            </div>
            :
            <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                 <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} class="cat">
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',}} className='sub_cat_flex'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux d'utilisateurs unique par rapport aux contenus crées</p>
                        <div style={{flexDirection:'column', display:'flex', flex:1}}>    
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                <p style={{color:percentUniqUsers > percentUniqUsersOld ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentUniqUsers}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.contentsCreated[0]?.totalUsers} utilisateurs / {this.state.contentsCreated[0]?.contentsNB} contenus</p>
                            </div>
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentUniqUsersOld}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.contentsCreated[1]?.totalUsers} utilisateurs / {this.state.contentsCreated[1]?.contentsNB} contenus</p>
                            </div>
                        
                        </div>
                        
                    </div>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Contenus crées par jour <span style={{fontWeight:'bold', color:percentContents > 0 ? 'green' : 'red', marginLeft:10}}>{percentContents > 0 ? '+' : ''}{percentContents}%</span></p>
                        {this.state.dataContents.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgb(255,255,255,0.5)']} color={'white'} borderRadius={15}
                        xAxis={[{scaleType:'point', data: this.state.dataContents[0]?.xAxis, }]}
                        series={[
                            {
                            data: this.state.dataContents[0]?.series,
                            }, {
                                data: this.state.dataContents[1]?.series,
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                
                </div>
                <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px'}} className='cat'>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',flex:1}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux de clic sur un contenu par rapport aux utilisateurs actifs</p>
                        <div style={{flexDirection:'column', display:'flex', flex:1}}>    
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                <p style={{color:percentSeen > percentSeenOld ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentSeen}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.contentsSeen[0]?.seenNB} clics / {this.state.contentsSeen[0]?.usersNB} utilisateurs</p>
                            </div>
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentSeenOld}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.contentsSeen[1]?.seenNB} clics / {this.state.contentsSeen[1]?.usersNB} utilisateurs</p>
                            </div>
                        
                        </div>
                        
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',flex:1}}  className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux de reposts par rapport aux contenus</p>
                        <div style={{flexDirection:'column', display:'flex', flex:1}}>    
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                <p style={{color:percentReposts > percentRepostsOld ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentReposts}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.repostsNB[0]} reposts / {this.state.contentsCreated[0]?.contentsNB} contenus</p>
                            </div>
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentRepostsOld}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.repostsNB[1]} reposts / {this.state.contentsCreated[1]?.contentsNB} contenus</p>
                            </div>
                        
                        </div>
                        
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',flex:1}}  className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Ratio interactions / contenus crées</p>
                        <div style={{flexDirection:'column', display:'flex', flex:1}}>    
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'white', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                <p style={{color:this.state.popularScore[0] > this.state.popularScore[1] ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{parseFloat(this.state.popularScore[0]).toFixed(2)}</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.contentsCreated[0]?.contentsNB} contenus</p>
                            </div>
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:15, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{parseFloat(this.state.popularScore[1]).toFixed(2)}</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:'white'}}>{this.state.contentsCreated[1]?.contentsNB} contenus</p>
                            </div>
                        
                        </div>
                        
                    </div>
                
                </div>
              
            </div>
            }
        </div>
      );
    }
  }