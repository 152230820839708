import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class Registers extends React.Component {
    constructor(props) {
      super(props);
        console.log(props.dates)
      this.state = {isLoading: false,error:false, dates:this.props.dates,dataSpendTime:{},dataDeleted:[],spendTimeUsersNB:[],registeredUsersNB:[],dataRegisteredPlatform:[], dataCountries:[], birthdateDatas:{}, dataUsersConnectedSince:{},datasUsersActions:[]};
     
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
        console.log('dates', this.state.dates)
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsRegister.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer.dataSpendTime)
                    if(responseJsonFromServer.statusCode == 1){
               
                        this.setState({isLoading:false, 
                            dataSpendTime:responseJsonFromServer.dataSpendTime,
                            registeredUsersNB:responseJsonFromServer.registeredUsersNB,
                            dataDeleted:responseJsonFromServer.dataDeleted,
                            dataRegisteredPlatform:responseJsonFromServer.dataRegisteredPlatform,
                            spendTimeUsersNB:responseJsonFromServer.spendTimeUsersNB,
                            dataCountries:responseJsonFromServer.dataCountries,
                            birthdateDatas:responseJsonFromServer.birthdateDatas,
                            dataUsersConnectedSince:responseJsonFromServer.dataUsersConnectedSince,
                            datasUsersActions:responseJsonFromServer.datasUsersActions
                       })
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
        // var dates = [props.dates[0].format('YYYY-MM-DD')];
        
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })  
       }
    }
 
    render() {
        if(this.state.dataDeleted.length > 0){
            var dataset = this.state.dataDeleted.map(([high, low, order]) => ({
                high,
                low,
                order,
              }));
        }
        if(this.state.dataRegisteredPlatform.length > 0){
            var datasetPlatform = this.state.dataRegisteredPlatform.map(([high, low, order]) => ({
                high,
                low,
                order,
              }))
        }
        if(this.state.datasUsersActions.length > 0){
            var datasetActions = this.state.datasUsersActions.map(([high, low, order]) => ({
                high,
                low,
                order,
              }))
        }
        var age1 = 33;
        var age2 = 33;
        var age3 = 33;
        if(Object.keys(this.state.birthdateDatas).length > 0){
            var ageGroup = this.state.birthdateDatas.ageGroup;
            var ageTotal = (parseInt(ageGroup.age1)+parseInt(ageGroup.age2)+parseInt(ageGroup.age3))
            age1 = ((ageGroup.age1)/parseInt(ageTotal))*100
            age2 = ((parseInt(ageGroup.age2))/parseInt(ageTotal))*100
            age3 = ((parseInt(ageGroup.age3))/parseInt(ageTotal))*100
        
        }
     
        var percentRegisters;
        if(this.state.registeredUsersNB.length > 0){
            percentRegisters = Math.round(((parseInt(this.state.registeredUsersNB[0])-parseInt(this.state.registeredUsersNB[1]))/parseInt(this.state.registeredUsersNB[1]))*100)
        }
        var percentSpendTime;
        if(this.state.spendTimeUsersNB.length > 0){
            percentSpendTime = Math.round(((parseInt(this.state.spendTimeUsersNB[0])-parseInt(this.state.spendTimeUsersNB[1]))/parseInt(this.state.spendTimeUsersNB[1]))*100)
        }
      return (
        <div style={{flex:1, display:'flex'}}>
            {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="medium" text="" textColor="" />
            </div>
            :
            <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                 <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} className="cat">

                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Inscriptions <span style={{fontWeight:'bold', color:percentRegisters > 0 ? 'green' : 'red', marginLeft:10}}>{percentRegisters > 0 ? '+' : ''}{percentRegisters}%</span></p>
                        {this.state.dataRegisteredPlatform.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={['white', '#9fc037']} color={'white'}  series={[
                        { dataKey: 'high', label: 'IOS', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Android', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={datasetPlatform} /></ThemeProvider>}
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Temps moyen passé des nouveaux (en min) <span style={{fontWeight:'bold', marginLeft:10,color:percentSpendTime > 0 ? 'green' : 'red'}}>{percentSpendTime > 0 ? '+' : ''}{percentSpendTime}%</span></p>
                        {this.state.dataSpendTime.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={['white', 'rgba(255,255,255,0.5)']} color={'white'}  borderRadius={15}
                        xAxis={[{scaleType:'point', data: this.state.dataSpendTime[0]?.xAxis, },]}
                        series={[
                            {
                            data: this.state.dataSpendTime[0]?.series,
                            },  {
                                data: this.state.dataSpendTime[1]?.series,
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                    
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Suppressions de comptes par inscription</p>
                        {this.state.dataDeleted.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={['white', 'red']} color={'white'}  series={[
                        { dataKey: 'high', label: 'Inscriptions', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Suppressions', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={dataset} /></ThemeProvider>}
                    </div>
                    
                </div>
                <div style={{flex:1, display:'flex', columnGap:'25px',  margin:'25px', marginBottom:'0px'}} className='cat'>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Pays d'inscription sur cette tranche</p>
                        <ThemeProvider theme={darkTheme}>               
                        <PieChart 
                        series={[
                            {
                            data: this.state.dataCountries, innerRadius: 36,
                            outerRadius: 100,
                            paddingAngle: 5,
                            cornerRadius: 5,
                            startAngle: -45,
                            endAngle: 225,
                            cx: 150,
                            cy: 150,
                            },
                        ]}
                        />
                        </ThemeProvider>
                    </div>
              
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Age</p>
                        <div style={{display:'flex', flex:1, flexDirection:'row', padding:25}}>
                            <div style={{alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'column', padding:25,flex:1}}>
                                <p style={{color:'white', fontSize:15, fontWeight:'bold'}}>Age moyen</p>
                                <p  style={{color:'white', fontSize:19, fontWeight:'bold'}}>{this.state.birthdateDatas?.mid} ans</p>
                            </div>
                            <div style={{display:'flex', flex:1, flexDirection:'column',  borderLeft:'1px solid rgba(255,255,255,0.2)',  alignItems:'center', justifyContent:'center'}}>             
                                <ThemeProvider theme={darkTheme}>          
                                    <PieChart  slotProps={{legend:{ hidden: true }, }}   margin={{ right: 0, left:25 }}
                                    series={[
                                        {
                                            arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                        data: [
                                            { id: 0, value: age1, label: '15-17', color:'rgb(0, 186, 173)' },
                                            { id: 1, value: age2, label: '18-21', color:"rgb(42, 123, 155)" },
                                            { id: 2, value: age3, label: '22+', color:"rgb(61, 61, 107)" },
                                        
                                        ],
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                        fontWeight: 'bold',
                                        color:'white'
                                        },
                                    }}
                                
                                    />
                                </ThemeProvider>
                              
                            </div>
                        </div>
                    </div>

                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'> 
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux d'utilisateurs revenu depuis J1</p>
                        <div style={{flex:1, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                            <p style={{color:'white', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round((parseInt(this.state.dataUsersConnectedSince?.reconnected))/parseInt(this.state.dataUsersConnectedSince?.all)*100)}%</p>
                            <p style={{color:'white', fontSize:25, padding:10, margin:0 }}>{this.state.dataUsersConnectedSince?.reconnected} sur {this.state.dataUsersConnectedSince?.all}</p>
                        </div>
                    </div>
                </div>
                <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}}>
                    <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Actions d'utilisateurs unique</p>
                        {this.state.datasUsersActions.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <BarChart colors={['white', 'green']} color={'white'}  series={[
                        { dataKey: 'high', label: 'Total', layout : 'vertical', stack: 'stack' },
                        { dataKey: 'low', label: 'Actions', layout:'vertical', stack: 'stack' },
                        ]} borderRadius={15}    xAxis={[{ scaleType: 'band', dataKey: 'order' }]} yAxis={undefined}
                        dataset={datasetActions} /></ThemeProvider>}
                    </div>
                </div>
            </div>
            }
        </div>
      );
    }
  }