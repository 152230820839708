
import { useEffect } from 'react';
import './App.css';
import Home from './components/Home'
import Connect from './components/Connect'
function App() {

  return (
    <div className='App'>
        {localStorage.getItem("token") == null ?
        <Connect/> : <Home/>}
    </div>
  );
}

export default App;
